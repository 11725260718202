import MtnMoneyLogo from "assets/mtn.png";
import orangeMoneyLogo from "assets/svg/orange.svg";
import expressUnionMoneyLogo from "assets/svg/eu.svg";
import yoomeeMoneyLogo from "assets/svg/yoomee.svg";
import yupMoneyLogo from "assets/yup.png";

export const renderLogo = (id) => {
  switch (id) {
    case "MTNMOMO":
    case "MOMO":
      return MtnMoneyLogo;
    case "CMMTNMOMOCC":
    case "CMMTNMOMOCO":
      return MtnMoneyLogo;
    case "CMORANGEMOMO":
    case "ORANGEWPAY":
    case "CMORANGEOMCC":
    case "CMORANGEOM":
      return orangeMoneyLogo;
    case "EUCASHOUT":
    case "CMEXPRESSUNIONCC":
      return expressUnionMoneyLogo;
    case "CMYOOMEEMONEYCC":
    case "CMYOOMEEMONEY":
      return yoomeeMoneyLogo;
    case "CMYUPCC":
      return yupMoneyLogo;
    default:
      break;
  }

  return "";
};
